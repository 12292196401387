import { css } from '@emotion/react';
import AltImage from '@landing/src/common/AltImage';
import { repository } from '@landing/src/repository';
import { utils } from '@landing/src/utils';
import token from '@landing/src/utils/token';
import theme from '@landing/styles/theme';
import Color from 'color';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { MENULIST } from '../constants';
import { MobileIcon } from '../MobileIcon';

export default function DefaultHeader() {
  const router = useRouter();

  const isHome = router.pathname === '/';
  const color = isHome ? 'white' : '#454B66';
  const logoImage = isHome ? 'logo_white.png' : 'logo_color.png';

  const { accessToken, refreshToken } = token.get();

  // 휴먼 스튜디오 로고 이미지
  const humanStudioLogoImage = isHome
    ? 'human_studio_logo_white.png'
    : 'human_studio_logo_dark_gray.png';
  // 휴먼 스튜디오와 공통으로 사용하는 페이지에서는 메뉴버튼 숨김
  const hiddenPaths = [
    '/login',
    '/sign-up',
    '/sign-up/complete',
    '/mypage/editInfo',
    '/mypage/editpassword',
  ];
  const hideMenu = hiddenPaths.includes(router.pathname);

  const humanStudioURL = new URL(process.env.NEXT_PUBLIC_HUMANSTUDIO_URL);
  if (accessToken && refreshToken) {
    humanStudioURL.searchParams.set('access_token', accessToken);
    humanStudioURL.searchParams.set('refresh_token', refreshToken);
  }

  const scroll = utils.hooks.useScrollPosition();
  const p = Math.min(scroll.y / 170, 1.0);
  const background = Color.rgb(23 * p, 23 * p, 23 * p, 1 * p);
  const boxShadowPosition = `0px ${5 * p}px ${13 * p}px `;
  const boxShadowColor = Color.rgb(0, 0, 0, 0.15 * p);

  const { data: memberResponse } = repository.hooks.useMember();

  const { data: member } = memberResponse?.data ?? {};

  const signout = repository.hooks.useSignOut();

  return (
    <header
      style={{
        background: isHome ? background.string() : 'rgba(249, 249, 249, 1)',
        boxShadow: `${boxShadowPosition}${boxShadowColor.string()}`,
      }}
      css={css`
        position: fixed;
        z-index: 999;
        top: 0;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 32px;
        ${theme.tablet} {
          display: none;
        }
      `}
    >
      <div
        css={css`
          flex: 1;
          height: 100%;
          max-width: 1440px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            & > a {
              display: flex;
              justify-content: center;
            }
          `}
        >
          <Link href="/">
            <AltImage
              alt="KT AI STUDIO"
              src={`/images/${logoImage}`}
              width={186}
              height={18}
            />
          </Link>
          {hideMenu && (
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <span
                css={css`
                  color: ${color};
                  text-align: center;
                  margin: 0 16px 0 14px;
                `}
              >
                X
              </span>
              <a
                href={
                  accessToken || refreshToken
                    ? `${process.env.NEXT_PUBLIC_HUMANSTUDIO_URL}?access_token=${accessToken}&refresh_token=${refreshToken}`
                    : process.env.NEXT_PUBLIC_HUMANSTUDIO_URL
                }
              >
                <AltImage
                  alt="KT AI HUMAN STUDIO"
                  src={`/images/${humanStudioLogoImage}`}
                  width={200}
                  height={18}
                />
              </a>
            </div>
          )}
        </div>
        {!hideMenu && (
          <nav
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > a {
                padding: 0 40px;
                color: ${color};
                &:hover {
                  color: #3a95ff;
                }
              }
            `}
          >
            <Link
              href={member ? '/app' : '/login'}
              css={css`
                font-size: 16px;
                line-height: 100%;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              스튜디오
              <MobileIcon />
            </Link>

            {MENULIST.map(({ id, name, subMenu }) => {
              const firstSubMenu = subMenu[0];
              const href = firstSubMenu ? firstSubMenu.link : `/${id}`;
              const isSelected = router.asPath.startsWith(`/${id}`);
              const menuArrowSrc = isHome
                ? '/icons/ic_menu_arrow_w.png'
                : '/icons/ic_menu_arrow.png';
              return (
                <div
                  key={id}
                  css={css`
                    position: relative;
                    &:hover {
                      // 서브메뉴 표시
                      #container-sub-menu {
                        display: block;
                      }
                    }
                  `}
                >
                  <Link
                    href={href}
                    css={css`
                      color: ${isSelected ? '#3a95ff' : color};
                      font-size: 16px;
                      font-weight: ${isSelected ? 600 : 500};
                      line-height: 100%;
                      padding: 0 40px;
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <span
                      css={css`
                        position: relative;
                      `}
                    >
                      {isSelected && (
                        <mark
                          css={css`
                            position: absolute;
                            left: 50%;
                            top: -8px;
                            transform: translate(-50%, 0);
                            width: 4px;
                            height: 4px;
                            background: #3a95ff;
                            border-radius: 50%;
                          `}
                        />
                      )}
                      {name}
                    </span>
                    {firstSubMenu && (
                      <AltImage
                        alt=""
                        src={menuArrowSrc}
                        width={16}
                        height={16}
                      />
                    )}
                  </Link>

                  {/* 서브메뉴 */}
                  {firstSubMenu && (
                    <aside
                      id="container-sub-menu"
                      css={css`
                        margin-top: 15px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        min-width: 200px;
                        border-radius: 10px;
                        display: none; // 메뉴 호버 시 block으로 변경됨
                        padding-top: 24px;
                      `}
                    >
                      <nav
                        css={css`
                          box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.09);
                          background: white;
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          gap: 20px;
                          padding: 24px 30px;
                        `}
                      >
                        {subMenu.map(({ icon, link, name }) => (
                          <Link
                            key={link}
                            href={link}
                            css={css`
                              display: flex;
                              align-items: center;
                              gap: 8px;
                            `}
                          >
                            <AltImage
                              alt={name}
                              src={icon}
                              width={24}
                              height={24}
                            />
                            <span>{name}</span>
                          </Link>
                        ))}
                      </nav>
                    </aside>
                  )}
                </div>
              );
            })}

            <Link href={humanStudioURL.href}>Human Studio</Link>
          </nav>
        )}

        <div
          css={css`
            height: 32px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            & > * {
              width: 80px;
              height: 100%;
              font-size: 12px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          `}
        >
          {member && (
            <>
              <Link
                href="/mypage"
                css={css`
                  &:hover {
                    background: #548bf6;
                    color: white;
                  }
                  background: ${color};
                  color: ${isHome ? '#171717' : 'white'};
                `}
              >
                마이페이지
              </Link>
              <button
                type="button"
                disabled={signout.isMutating}
                onClick={() => signout.trigger()}
                css={css`
                  background: transparent;
                  border: 1px solid ${color};
                  color: ${color};
                  &:hover {
                    border: 1px solid #548bf6;
                    color: #548bf6;
                  }
                `}
              >
                로그아웃
              </button>
            </>
          )}
          {!member && (
            <>
              <Link
                href="/login"
                css={css`
                  background: ${color};
                  color: ${isHome ? '#171717' : 'white'};
                  &:hover {
                    background: #548bf6;
                    color: white;
                  }
                `}
              >
                로그인
              </Link>
              {/* <Link
                href="/sign-up"
                css={css`
                  &:hover {
                    border: 1px solid #548bf6;
                    color: #548bf6;
                  }
                  background: transparent;
                  border: 1px solid ${color};
                  color: ${color};
                `}
              >
                회원가입
              </Link> */}
            </>
          )}
        </div>
      </div>
    </header>
  );
}
